import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { Form, PrimaryButton, FieldCurrencyInput, Icons } from '../../../components';
import appSettings from '../../../config/settings';
import { getPriceValidators } from '../../EditListingPage/EditListingWizard/EditListingPricingAndStockPanel/EditListingPricingAndStockForm';
import { BASE_CURRENCY, BASE_CURRENCY_SYMBOL, LISTING_MIN_PRICE } from '../../../util/enums';

import css from './Offers.module.css';
import { calculatePercentage } from '../../../util/genericHelpers';

const OfferFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        handleSubmit,
        intl,
        price,
        listingTitle,
        authorDisplayName,
        sendEnquiryError,
        invalid,
        inquiryInProgress,
        form,
        isCounterOffer,
      } = fieldRenderProps;

      const classes = classNames(rootClassName || css.root, className);

      const priceValidators = getPriceValidators(LISTING_MIN_PRICE, BASE_CURRENCY, intl);

      const matomoEvent = (amount) => {
        // Matomo
        if (typeof _paq !== 'undefined') {
          _paq.push(['trackEvent', 'Shopping', 'ShopMakeOffer', amount]);
        }
      };

      const customHandleSubmit = async (event) => {
        const values = form.getState().values;
        const amount = values.price.amount / 100;
        matomoEvent(amount);
        handleSubmit(event);
      };

      return (
        <Form className={classes} onSubmit={customHandleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          {!isCounterOffer && <div className={css.offerFormHead}>
            <Icons name="offersIcon" />
            <h2 className={css.heading}>
              <FormattedMessage
                id="OfferForm.heading"
                values={{ listingTitle, authorDisplayName }}
              />
            </h2>
            <p className={css.description}>
              <FormattedMessage id="OfferForm.description" />
            </p>
          </div>}
          <FieldCurrencyInput
            id="price"
            name="price"
            className={css.input}
            placeholder={`${calculatePercentage(price?.amount / 100, 90).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${BASE_CURRENCY_SYMBOL}`}
            currencyConfig={appSettings.getCurrencyFormatting(BASE_CURRENCY)}
            validate={priceValidators}
          />
          <div className={css.actionBtns}>
            {sendEnquiryError ? (
              <p className={css.error}>
                <FormattedMessage id="OfferForm.sendEnquiryError" />
              </p>
            ) : null}
            <PrimaryButton
              className={css.submitBtn}
              type="submit"
              inProgress={inquiryInProgress}
              disabled={invalid}
            >
              <FormattedMessage id="OfferForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

OfferFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

OfferFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const OfferForm = compose(injectIntl)(OfferFormComponent);

OfferForm.displayName = 'OfferForm';

export default OfferForm;
